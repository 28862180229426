import Vue from 'vue'
import VueRouter from 'vue-router'
import mobileLayout from '../layouts/mobile/layout.vue'
import pcLayout from '../layouts/pc/layout.vue'
Vue.use(VueRouter)

let routes = null

const routesP = [
  {
    path: '/',
    component: pcLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: () =>
          import(
            /* webpackChunkName: "PcHome" */
            '../views/pc/home.vue'
          )
      },
      {
        path: 'cause',
        name: 'cause',
        component: () =>
          import(
            /* webpackChunkName: "PcCause" */
            '../views/pc/cause.vue'
          )
      },
      {
        path: 'association',
        name: 'association',
        component: () =>
          import(
            /* webpackChunkName: "PcAssociation" */
            '../views/pc/association.vue'
          )
      },
      {
        path: 'brand',
        name: 'brand',
        component: () =>
          import(
            /* webpackChunkName: "PcBrand" */
            '../views/pc/brand.vue'
          )
      },
      {
        path: 'drive',
        name: 'drive',
        component: () =>
          import(
            /* webpackChunkName: "PcDrive" */
            '../views/pc/drive.vue'
          )
      },
      {
        path: 'shield',
        name: 'shield',
        component: () =>
          import(
            /* webpackChunkName: "PcDrive" */
            '../views/pc/shield.vue'
          )
      },
    ]
  }
]

const routesM = [
  {
    path: '/',
    component: mobileLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: () =>
          import(
            /* webpackChunkName: "MobileHome" */ '../views/mobile/home.vue'
          )
      },
      {
        path: 'cause',
        name: 'cause',
        component: () =>
          import(
            /* webpackChunkName: "MobileCause" */ '../views/mobile/cause.vue'
          )
      },
      {
        path: 'brand',
        name: 'brand',
        component: () =>
          import(
            /* webpackChunkName: "MobileBrand" */ '../views/mobile/brand.vue'
          )
      },
      {
        path: 'drive',
        name: 'drive',
        component: () =>
          import(
            /* webpackChunkName: "MobileDrive" */ '../views/mobile/drive.vue'
          )
      },
      {
        path: 'association',
        name: 'association',
        component: () =>
          import(
            /* webpackChunkName: "MobileAssociation" */ '../views/mobile/association.vue'
          )
      },
      {
        path: 'shield',
        name: 'shield',
        component: () =>
          import(
            /* webpackChunkName: "PcDrive" */
            '../views/mobile/shield.vue'
          )
      },
    ]
  }
]

if (
  navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
) {
  routes = routesM
} else {
  routes = routesP
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
