<template>
  <div class="layout-container">
    <div class="header">
      <div class="_headerCenter">
        <div class="_headerLeft" @click="goHome">
          <img src="@/assets/footer-logo.png" />
          <div class="text">三恒一樹株式会社</div>
        </div>
        <ul class="_headerRight">
          <li class="_list" v-for="(item, index) in navigators" :key="index" @click="goPage(item)">
            {{ item.text }}
          </li>
        </ul>
      </div>
    </div>

    <div class="conterCenter">
      <router-view />
    </div>

    <div class="footer">
      <div class="footer_container">
        <img src="@/assets/footer-logo.png">
        <div class="footer_text">
          <div class="cn_name">三恒一樹株式会社</div>
          <div class="en_name">SANKOUIKKI</div>
          <div class="tel">〒274-0065</div>
          <div class="address">千葉県船橋市高根台6-2-25</div>
          <div class="right">Copyright SANKOUIKKI co. ltd. All rights</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PcLayout",

  data () {
    return {
      showPopup: false,
      navigators: [
        {
          text: "HOME",
          type: "home",
        },
        {
          text: "事業紹介",
          type: "cause",
        },
        {
          text: "取り扱いブランド",
          type: "brand",
        },
        {
          text: "営業拠点",
          type: "shield",
        },
        {
          text: "ドライビングレッスン",
          type: "drive",
        },
        {
          text: "会社概要",
          type: "association",
        },
      ],
      currentPage: 0,
    };
  },
  watch: {
    $route: {
      handler (to) {
        switch (to.name) {
          case "home":
            this.currentPage = 0;
            break;
          case "cause":
            this.currentPage = 1;
            break;
          case "brand":
            this.currentPage = 2;
            break;
          case "shield":
            this.currentPage = 3;
            break;
          case "drive":
            this.currentPage = 4;
            break;
          case "association":
            this.currentPage = 5;
            break;
        }
      },
      immediate: true, //第一次就执行
    },
  },
  methods: {
    handlePopup () {
      this.showPopup = !this.showPopup;
    },
    goPage (e) {
      if (this.$route.name === e.type) return;
      switch (e.type) {
        case "home":
          this.$router.push({ name: "home" });
          break;
        case "cause":
          this.$router.push({ name: "cause" });
          break;
        case "brand":
          this.$router.push({ name: "brand" });
          break;
        case "shield":
          this.$router.push({ name: "shield" });
          break;
        case "drive":
          this.$router.push({ name: "drive" });
          break;
        case "association":
          this.$router.push({ name: "association" });
          break;
      }
    },
    goHome () {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;

  .conterCenter {
    padding-top: 64px;
    flex: 1
  }

  .header {
    position: fixed;
    z-index: 5;
    height: 64px;
    width: 100%;
    background: rgba(0, 0, 0);
    box-shadow: 0 2px 5px 0 rgba(255, 255, 255, 0.1);
    display: flex;

    ._headerCenter {
      position: relative;
      min-width: 1200px;
      width: 1200px;
      height: 100%;
      height: 64px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      ._headerLeft {
        height: 53px;
        display: flex;
        // background-image: url('../../assets/footer-logo.png');
        // background-position: center center;
        // background-repeat: no-repeat;
        // background-size: auto 100%;
        margin: auto 0;
        cursor: pointer;

        .text {
          line-height: 53px;
          font-size: 30px;
          align-items: center;
          padding-left: 10px;
        }
      }

      ._headerRight {
        display: flex;

        ._list {
          cursor: pointer;
          height: 64px;
          padding: 0 20px;
          line-height: 64px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #fff;
          letter-spacing: 0.14px;
          border-bottom: 3px solid transparent;
          transition: border-color 0.2s linear;
        }
      }
    }
  }

  .footer {
    width: 100%;
    background-color: #000;
    padding: 30px 0;

    .footer_container {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      color: #fff;
    }

    img {
      width: 200px;
      margin-right: 60px;
    }

    .footer_text {
      padding-top: 20px;
      text-align: left;
      letter-spacing: 1px;

      .cn_name {
        font-size: 20px;
        line-height: 40px;
        font-weight: 600;
      }

      .en_name {
        font-size: 18px;
        font-weight: lighter;
        line-height: 30px;
        letter-spacing: 0px;
      }

      .tel {
        font-size: 14px;
        margin: 10px 0;
      }

      .address {
        font-size: 14px;
        margin: 10px 0;
      }

      .right {
        font-size: 12px;
        margin: 10px 0;
      }
    }
  }
}
</style>
