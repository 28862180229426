import Vue from 'vue'
import {
  Icon,
  Popup,
  Grid,
  GridItem,
  Popover,
  Swipe,
  SwipeItem,
  Image as VanImage
} from 'vant'

Vue.use(Icon)
Vue.use(Popup)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Popover)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(VanImage)
