<template>
  <div class="body">
    <div class="layout-container">
    <div class="header">
      <van-popover
        v-model="showPopup"
        trigger="click"
        :actions="menuList"
        @select="goPage($event)"
      >
        <template #reference>
          <van-icon name="wap-nav" class="menu-icon" />
        </template>
      </van-popover>
      <div class="header-img">
        <div @click="goHome">
          <img src="@/assets/logo.png" />
          <!-- <span>MOTO VISION LAND</span> -->
        </div>
      </div>
    </div>
    <router-view />
  </div>
  <div class="footer">
      <div class="footer_container">
        <div class="img">
          <img src="@/assets/footer-logo.png">
        </div>
        <div class="footer_text">
          <div class="cn_name">三恒一樹株式会社</div>
          <div class="en_name">SANKOUIKKI</div>
          <div class="tel">〒274-0065</div>
          <div class="address">千葉県船橋市高根台6-2-25</div>
          <div class="right">Copyright SANKOUIKKI co. ltd. All rights</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileLayout',
  data() {
    return {
      showPopup: false,
      menuList: [
        {
          text: 'HOME',
          type: 'home'
        },
        {
          text: '事業紹介',
          type: 'cause'
        },
        {
          text: '取り扱いブランド',
          type: 'brand'
        },
        {
          text: '営業拠点',
          type: 'shield'
        },
        {
          text: 'ドライビングレッスン',
          type: 'drive'
        },
        {
          text: '会社概要',
          type: 'association'
        }
      ],
      currentPage: 0
    }
  },
  watch: {
    $route: {
      handler(to) {
        switch (to.name) {
          case 'home':
            this.currentPage = 0
            break
          case 'cause':
            this.currentPage = 1
            break
          case 'brand':
            this.currentPage = 2
            break
          case 'drive':
            this.currentPage = 3
            break
          case 'association':
            this.currentPage = 4
            break
        }
      },
      immediate: true //第一次就执行
    }
  },
  methods: {
    handlePopup() {
      this.showPopup = !this.showPopup
    },
    goPage(e) {
      if (this.$route.name === e.type) return
      switch (e.type) {
        case 'home':
          this.$router.push({ name: 'home' })
          break
        case 'cause':
          this.$router.push({ name: 'cause' })
          break
        case 'brand':
          this.$router.push({ name: 'brand' })
          break
        case 'drive':
          this.$router.push({ name: 'drive' })
          break
        case 'association':
          this.$router.push({ name: 'association' })
          break
        case 'shield':
          this.$router.push({ name: 'shield' })
          break
      }
    },
    goHome() {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
}
.layout-container {
  padding: 10px;
  flex:1;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    .menu-icon {
      font-size: 35px;
    }
    .header-img {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        // width: 80%;
        // min-width: 220px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        font-size: 14px;
        font-weight: 800;
        box-shadow: 1px 0 3px 4px rgb(0, 0, 0),
          3px 2px 3px 4px rgb(255, 255, 255, 0.7);
        img {
          height: 35px;
          width: 220px;
        }
        span {
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}
.footer {
    width: 100%;
    background-color: #000;
    padding: 10px ;

    .footer_container {
      width: 100%;
      margin: 0 auto;
      display: flex;
      color: #fff;
    }

    .img { 
      width: 120px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 90%;
      }
    }

    .footer_text {
      text-align: left;
      letter-spacing: 1px;

      .cn_name {
        font-size: 16px;
        line-height: 30px;
        font-weight: 600;
      }

      .en_name {
        font-size: 14px;
        font-weight: lighter;
        line-height: 20px;
        letter-spacing: 0px;
      }

      .tel {
        font-size: 12px;
        margin: 10px 0;
      }

      .address {
        font-size: 12px;
        margin: 10px 0;
      }

      .right {
        font-size: 10px;
        margin: 10px 0;
      }
    }
  }
</style>

<style lang="scss">
.van-popover--light {
  z-index: 2001 !important;
  position: absolute !important;
  left: 10px !important;
  top: 50px !important ;
  margin: 0px !important;
  color: #fff !important;
  .van-popover__arrow {
    border-width: 0px !important;
  }
  .van-popover__content {
    border-radius: 5px !important;
    background-color: #000 !important;
  }
}
</style>
