import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'normalize.css/normalize.css';
import './plugins/vant';
import preview from 'vue-photo-preview';
import 'vue-photo-preview/dist/skin.css';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(preview);
Vue.use(ElementUI,{ size: 'small'});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
